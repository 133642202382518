.anim2_container div,
.anim2_container {
  display: flex;
  justify-content: center;
  transition: 0.75s ease;
}
.anim2_container {
  position: absolute;
  min-width: auto;
  height: auto;
  font-size: 16px;
  line-height: 1.5em;
  bottom: 22px;
  right: 13px;
  left: 30px;
  top: 120px;
  transition: none !important;
}
.anim2_scaler {
  width: 100%;
  height: 550px;
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: none !important;
}

.anim2_container .table-label {
  align-items: center;
  background-color: #252525;
  color: #b5b5b5;
}
.anim2_container .tables {
  background-color: #121212;
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0;
  display: flex;
  color: #e0e0e0;
  box-shadow: 0 0 5px rgba(0 0 0 / 70%);
}
.anim2_container .table-label span {
  transform: rotate(-90deg);
}
.anim2_container .table-label {
  width: 20%;
}
.anim2_container .table {
  width: 20%;
}
.anim2_container .call {
  background: #252525;
  padding: 20px;
  margin-top: 10px;
  text-align: center;
  color: #e0e0e0;
  box-shadow: 3px 3px 0px rgb(0 0 0 / 10%);
  position: absolute;
  top: 10px;
  right: -100%;
  border-radius: 20px 20px 0px 20px;
}
.anim2_container .staff {
  border-radius: 20px 20px 20px 0;
  right: unset;
  top: 70px;
  left: -100%;
}
.anim2_container .time {
  color: #121212;
  background-color: #47aecc;
  position: absolute;
  bottom: 35px;
  right: 5px;
  padding: 3px 7px;
  border-radius: 32px;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: normal;

  margin-right: 2px;
}
.anim2_container .time.auto {
  margin-right: 0;
  color: #b5b5b5;
  background: none;
  bottom: 35px;
  opacity: 1;
}
.anim2_container .first {
  right: 200px;
}
.anim2_container .second {
  right: 135px;
}
.anim2_container .third {
  right: 70px;
}
.anim2_container .fourth {
  right: 5px;
}
.anim2_container .time div {
  opacity: 0.5;
  margin-top: 3px;
}
.anim2_container .time div.optimal {
  opacity: 1;
  color: #47aecc;
}
.anim2_container .final_notes {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  height: fit-content;
  margin-top: 7px;
  color: white;
  z-index: 101;
  justify-content: flex-start;
  max-width: 100%;
}

.anim2_container .note {
  text-align: left;
  align-items: center;
  margin: 10px 20px;
  position: unset;
  width: 80%;
  opacity: 1;
  align-self: center;
  opacity: 1;
  justify-content: flex-start;
  z-index: 101;
  font-size: 0.9em;
  padding: 10px;
}

.anim2_container .note .time {
  position: unset;
  left: unset;
  right: unset;
  bottom: unset;
  top: unset;
  display: flex;
  opacity: 1;
  margin-right: 7px;
}

.anim2_container .note.replay {
  justify-content: center;
}

.anim2_container .replay-img {
  height: 70px;
  width: 70px;
  background-color: #47aecc;
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTg3LjMzMyw0Ni4xMzdMMTcuMTI4LDUuNjA0Yy0yLjk3NC0xLjcxNy02LjY5MSwwLjQyOS02LjY5MSwzLjg2M3Y4MS4wNjVjMCwzLjQzNCwzLjcxNyw1LjU4LDYuNjkxLDMuODYzbDcwLjIwNC00MC41MzIgICBDOTAuMzA3LDUyLjE0Niw5MC4zMDcsNDcuODU0LDg3LjMzMyw0Ni4xMzd6Ij48L3BhdGg+PC9nPjwvc3ZnPg==);
  mask-size: 65px 50px;
  -webkit-mask-size: 65px 50px;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: 8px;
  mask-img: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTg3LjMzMyw0Ni4xMzdMMTcuMTI4LDUuNjA0Yy0yLjk3NC0xLjcxNy02LjY5MSwwLjQyOS02LjY5MSwzLjg2M3Y4MS4wNjVjMCwzLjQzNCwzLjcxNyw1LjU4LDYuNjkxLDMuODYzbDcwLjIwNC00MC41MzIgICBDOTAuMzA3LDUyLjE0Niw5MC4zMDcsNDcuODU0LDg3LjMzMyw0Ni4xMzd6Ij48L3BhdGg+PC9nPjwvc3ZnPg==);
}
.anim2_container .replay-button {
  background-color: #252525;
  padding: 10px;
  border-radius: 100px;
  border: 3px solid #b5b5b5;
  color: #47aecc;
  box-shadow: 0 0 10px rgb(181 181 181 / 30%);
  cursor: pointer;
}
.anim2_container .replay-button:hover {
  border: 3px solid #47aecc;
  box-shadow: 0 0 10px rgb(71 174 204 / 70%);
}
.anim2_container .blackout {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 100;
  opacity: 0;
}
#id2_time_2 {
  right: 70px;
  bottom: 35px;
  opacity: 0;
}
#id2_time_3 {
  right: 70px;
  bottom: 10px;
  opacity: 0;
}
.anim2_container h1 {
  font-size: 1.2em;
}
