html {
  overflow-y: scroll;
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 1px;
  min-height: 100%;
}
p.complex {
  font-size: 0.8em;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span.code {
  color: #9c9c9c;
  font-style: italic;
  font-weight: bold;
  font-family: monospace;
}
html,
body {
  background: #ffffff00;
  color: #e0e0e0;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}
div#root,
div#root > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.terms h3 {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  text-decoration: underline;
}
.terms h4 {
  text-align: left;
  text-decoration: underline;
}
.page {
  min-height: 100vh;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 105px;
  background: rgb(42 42 42);
  padding-bottom: 300px;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - calc(2vw + 300px)),
    0 calc(100% - 300px)
  );
  z-index: 10;
}
.page-title {
  background: #286e8f;
  padding: 150px 0;
  margin-top: -2vw;
  text-align: center;
  color: rgb(255 255 255 / 80%);
  font-size: 70px;
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 1vw)); */
  width: 100%;
  line-height: normal;
  position: relative;
  color: transparent !important;
  line-height: 0;
}
.page-sub-title {
  font-family: "Montserrat";
  font-size: 35px;
  color: rgb(255 255 255 / 60%);
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  transform: translate(-50%, calc(-50% + 115px));
}
.page-sub-title i {
  color: rgb(255 255 255 / 80%);
  font-style: normal;
}
h2 {
  font-size: 36px;
  line-height: 50px;
  font-weight: 400;
  text-align: center;
}

h4 {
  font-size: 24px;
  line-height: 38px;
  font-weight: 400;
  text-align: center;
}

p {
  margin-top: 20px;
  margin-bottom: 20px;
}
.footer-wrapper {
  min-height: 200px;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 200px;
  background: #286e8f;
  color: white;
  font-size: 17px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.footer-wrapper > div {
  width: 400px;
  padding-right: 25px;
}
.footer-wrapper .left {
  text-align: right;
}

.footer-wrapper ul {
  list-style-type: none;
  margin: 0;
}
.footer-wrapper a {
  color: #2a2a2a;
  text-decoration: none;
  transition: 0.3s ease;
  border: 0;
}
.footer-wrapper a:hover {
  color: #47aecc;
}
.footer-wrapper p {
  font-size: 14px;
  line-height: normal;
}
span.host {
  font-family: monospace;
  font-weight: bold;
}
span.by {
  font-style: italic;
  font-size: 0.8em;
  padding: 0 0.3em;
}
span.strong {
  color: #47aecc;
  text-transform: uppercase;
  font-size: 15px;
}
button {
  cursor: pointer;
}
.message {
  width: 100%;
  margin-bottom: 150px;
  padding: 0px;
  font-family: "Montserrat";
  flex-direction: column;
}
p.statement {
  border: 2px solid grey;
  padding: 10px;
  background: rgb(128 128 128 / 10%);
}
span.super {
  vertical-align: super;
  font-size: 0.7em;
}
a:hover {
  color: #47aecc;
}
a {
  color: #286e8f;
  text-decoration: none;
  border-bottom: 2px solid;
  transition: 0.5s ease;
  white-space: nowrap;
}
.reading-time {
  color: grey;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: -20px;
  margin-top: 30px;
}
p.cta {
  padding: 60px;
  margin: 100px 60px 60px 60px;
  background: #252525;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}
p.cta h2 {
  margin-top: 0;
  padding-top: 0;
}
p.cta button {
  display: block;
  margin: 50px auto 0;
  background: #286e8f;
  border: 2px solid transparent;
  padding: 10px 15px;
  font-family: inherit;
  color: white;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;
}
p.cta button:hover {
  background: #47aecc;
}
.flash {
  animation: color-change 2s infinite;
}
.fast-flash {
  animation: color-change-double 1s infinite;
}
.flash:hover {
  color: white !important;
}
.feature-wrapper .graphic .show-hide {
  display: none;
  background-color: #47aecc;
}
.feature-wrapper .graphic .show-hide:hover {
  background-color: #91cee0;
}
.feature-wrapper .graphic .show-hide svg {
  transform: rotate(180deg);
  transition: 0.5s ease;
}

@keyframes color-change {
  0% {
    background: #47aecc;
  }
  50% {
    background: rgb(71 174 204 / 30%);
  }
  100% {
    background: #47aecc;
  }
}

@keyframes color-change-double {
  0% {
    background: #121212;
  }
  20% {
    background: #47aecc;
  }
  40% {
    background: #121212;
  }
  60% {
    background: #47aecc;
  }

  80% {
    background: #121212;
  }
}

.logo img {
  display: none;
}
.page-title img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% + 0.5vw));
  max-width: 90%;
}
.home .page-title {
  color: transparent !important;
  line-height: 0;
}
.home .page-title img {
  display: unset !important;
}
.cta a {
  margin-top: 3em;
  align-self: center;
}
.lets-see {
  display: none;
}
@media (max-width: 1100px) {
  .nav-wrapper .right a,
  .mobile-nav .right a {
    margin-right: 18px;
  }
  .nav-wrapper .links {
    min-width: 540px;
  }
  .logo {
    width: 0 !important;
  }
  .nav-wrapper .right {
    width: 100%;
  }
  .logo img {
    display: none;
  }
  .home .page-title {
    color: transparent !important;
  }
  .home .page-title img {
    display: unset !important;
  }
  .features-graphic .expand {
    display: flex !important;
  }
  .features-graphic {
    width: 100px !important;
    margin-right: 10px;
  }
}
@media (max-width: 1000px) {
  .landing-box {
    min-height: 480px !important;
  }
  .landing-box .img {
    width: 60% !important;
    right: calc(50% + 10px) !important;
  }

  .landing-box.even .img {
    right: unset;
    left: calc(50% + 10px) !important;
  }
  .landing-box .a-wrapper {
    width: calc(100% - 20px);
  }
}
/* @media (max-width: 800px) {
  .landing-box .img {
    width: 55% !important;
    top: calc(50% - 36px) !important;
    transform: translateY(-50%);
  }
} */
@media (max-width: 750px) {
  .feature-wrapper.walkin .graphic .show-hide {
    left: unset;
    right: -20px;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 0%), 0 0 0 10px #252525;
  }
  .feature-wrapper.m-vert.walkin {
    flex-direction: row !important;
  }
  .feature-wrapper.m-vert.walkin .graphic {
    align-self: flex-start !important;
    width: 100px !important;
    flex: unset;
    filter: none;
  }
  .feature-wrapper.m-vert.walkin .section {
    padding: 0 10px 0 0 !important;
  }

  .feature-wrapper.m-vert.walkin .graphic > div {
    pointer-events: none;
    width: 360px !important;
    max-height: 833px !important;
    transform: translateX(calc(-100% + 80px));
    left: 10px !important;
    filter: drop-shadow(2px 2px 3px #0003);
  }
  .feature-wrapper.m-vert.walkin .graphic > div.show {
    transform: translateX(0);
  }

  .feature-wrapper.m-vert.walkin .graphic img {
    pointer-events: none;
  }
  .lets-see {
    display: inline-block;
  }
  .feature-wrapper .graphic img {
    max-height: 700px;
  }
  .reset-next .explain {
    display: unset;
  }
  .reset-next .close {
    display: none;
  }

  .phone.feature-wrapper {
    display: block;
    width: 100%;
    position: relative;
  }
  .phone.feature-wrapper .content {
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100% - 100px);
  }
  .phone.feature-wrapper .content .section {
    width: 94% !important;
    margin-right: 6% !important;
    margin-left: 6% !important;
    padding: 0 !important;
  }

  .phone.feature-wrapper .content .section.expand {
    margin-left: -100% !important;
  }

  .phone.feature-wrapper .graphic {
    position: absolute;
    top: 0px;
    right: -5px;
  }
  .phone.feature-wrapper .graphic .section {
    position: absolute;
    top: 0;
    right: -350px;
    width: 400px;
  }
  .phone.feature-wrapper .graphic .section.show {
    right: 30px;
  }
  .phone.feature-wrapper .graphic .section .widget .controls,
  .phone.feature-wrapper .graphic .section .widget .controls ul {
    transition: none !important;
  }
  .feature-wrapper .graphic {
    pointer-events: none;
  }
  .feature-wrapper .graphic .show-hide {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
    border-radius: 50%;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 0%), 0 0 0 10px #1d1d1d;
    height: 40px;
    z-index: 300;
    width: 40px;
  }

  .feature-wrapper .graphic .section.show .show-hide svg {
    transform: rotate(0);
  }

  .feature-wrapper .graphic .section {
    filter: drop-shadow(-2px 2px 3px #0003);
    clip-path: polygon(
      -100px 1px,
      calc(100% + 100px) 1px,
      calc(100% + 100px) calc(100% - 1px),
      -100px calc(100% - 1px)
    );
    pointer-events: all;
  }

  .feature-wrapper .graphic .section img {
    margin-left: 15px;
    margin-top: 10px;
  }

  .feature-wrapper .graphic .section .widget.phone {
    margin-left: 0px;
    transform: translate(15px, 0);
  }
  .feature-wrapper .graphic .section .widget {
    top: 12.3%;
  }

  .host .message-container {
    z-index: 100;
    margin-top: -2vw;
    width: 100%;
  }
  .host .page-title {
    z-index: 200;
  }
  .host .message {
    width: 100%;
    max-width: 100% !important;
  }
  .host .selection {
    position: unset;
    margin: 0;
    padding: 0;
    min-width: unset;
    margin-top: -120px;
    margin-bottom: -20px;
    width: 100%;
  }
  .host .selection.show {
    margin-top: -100px;
    padding: 10vw;
  }
  .feature-wrapper.m-vert {
    flex-direction: column;
  }
  .feature-wrapper .graphic {
    max-width: 400px;
    align-self: center;
  }
  .feature-wrapper.m-vert .content {
    width: 90%;
    margin-left: 5%;
  }
}

@media (max-width: 650px) {
  .features-graphic {
    width: 20px !important;
  }
  .landing-box {
    flex-direction: column !important;
  }
  .landing-box h2 {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .landing-box .img {
    display: flex;
    flex: 1 !important;
    width: 100% !important;
    padding-top: 20px !important;
    position: unset !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .landing-box .a-wrapper {
    width: auto !important;
    bottom: 0 !important;
    transform: translate(-50%, 50%) !important;
  }

  .landing-box .text {
    padding: 20px !important;
  }
}
@media (max-width: 800px), (max-height: 800px) {
  .page {
    padding-top: 0px !important;
  }

  .nav-wrapper ul,
  .nav-wrapper .right,
  .nav-wrapper .links {
    display: none !important;
  }
  .nav-filter,
  .nav-wrapper {
    height: 0 !important;
    padding: 0 !important;
    clip-path: unset !important;
  }
  .nav-wrapper .burger-menu {
    display: flex !important;
    position: fixed;
    top: 15px;
    right: 15px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    padding: 5px;
    background: #2a2a2a;
    transition: 0.5s ease;
    color: #47aecc;
    border-radius: 50%;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
  }
  .nav-wrapper .burger-menu:hover,
  .nav-wrapper .burger-menu.active {
    background: #47aecc;
    color: white;
  }
}
@media (max-width: 600px) {
  .feature-wrapper.m-vert.walkin .graphic {
    width: 50px !important;
  }
  .feature-wrapper.m-vert.walkin .graphic > div {
    transform: translateX(calc(-100% + 20px));
  }
  .quote {
    margin: 50px 0 50px 0 !important;
  }
  p.cta {
    margin: 8vw;
    padding: 8vw;
    margin-top: 150px !important;
  }
  .contact form {
    margin: 1vw !important;
    padding: 10vw 5vw !important;
  }
  .footer-wrapper {
    padding-bottom: 15px;
  }
}
@media (max-width: 530px) {
  /* .host .content-wrapper {
    width: calc(100vw * 3) !important;
  } */

  .page-title {
    padding: 100px 0 !important;
  }
  .features-window {
    margin-right: -2px !important;
    margin-left: 0 !important;
  }
  .feature-wrapper .graphic .section .show-hide {
    top: 40px;
    left: 0;
    z-index: 300;
    transition: 0.5s ease;
  }
  .feature-wrapper .graphic .section.show .show-hide {
    left: 25px;
  }
  .phone.feature-wrapper .graphic .section.show {
    right: 18px;
  }
  .phone.feature-wrapper .content {
    width: calc(100% - 70px);
  }
}
@media (max-width: 450px) {
  .features-window {
    padding-left: 10px !important;
  }
  .quote svg.right {
    display: none;
  }

  .quote {
    padding-right: 20px !important;
  }

  .footer-wrapper {
    padding-bottom: 10px;
  }
  .footer-wrapper p {
    margin-bottom: 0;
  }
}
