.price {
  line-height: normal;
  text-align: center;
}
.price span {
  font-size: 100px;
  line-height: normal;
  display: inline;
}
.price-label {
  text-align: center;
  color: #e0e0e096;
}
.extra-info {
  margin: 50px 0;
  text-align: center;
}
.message-container {
  flex: 1;
}
.message {
  display: flex;
  align-items: center;
}
