.date-picker td {
  text-align: center;
  padding: 3px;
  background-color: #121212;
  cursor: pointer;
}
.date-picker td.different-month {
  text-align: center;
  padding: 3px;
  background-color: unset;
}
.date-picker td.past {
  opacity: 0.5;
  pointer-events: none;
}
.date-picker h3 {
  text-align: center;
  margin: 0;
  color: #47aecc;
}
.date-picker .past h3 {
  text-align: center;
  margin: 0;
  color: #cecece;
}

.date-picker td.selected {
  background: #47aecc;
}
.date-picker td.selected h3 {
  color: #121212;
}
.date-picker h2 {
  margin: 0 0 20px 0;
}
