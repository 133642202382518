.nav-filter {
  filter: drop-shadow(0 6px rgba(30 30 36 / 0%));
  z-index: 2100;
  position: fixed;
  width: 100%;
}
.nav-wrapper {
  background: #2a2a2a;
  display: flex;
  /* clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1vw), 0 100%); */
  z-index: 100;
  position: fixed;
  width: 100%;
  padding: 20px 0;
}
.nav-wrapper div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-wrapper .burger-menu {
  display: none;
}
.burger-menu svg {
  width: 35px;
  height: 40px;
  z-index: 100;
  pointer-events: none;
}
.burger-menu svg:hover {
  color: #91cee0;
}
.logo,
.right {
  width: 270px;
}
.logo img {
  transform: rotate(-0.5deg);
  margin-top: -5px;
  height: 120px;
  margin-left: 5px;
}
.logo {
  filter: invert(70%) sepia(22%) saturate(1117%) hue-rotate(150deg)
    brightness(85%) contrast(86%);

  justify-content: flex-start !important;
}
.links {
  flex-grow: 1;
  z-index: 100;
}
.links ul,
.mobile-nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
}
.links li {
  padding: 0 10px;
}

.links a,
.mobile-nav a {
  line-height: 2em;
  color: #47aecc;
  font-weight: 500;
  text-transform: uppercase;
  background: none;
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 15px;
  font-family: inherit;
  text-decoration: none;
  padding: 10px 0;
  border-bottom: 2px solid transparent;
}
.links a:hover,
.mobile-nav a:hover {
  color: #91cee0;
  border-bottom: 2px solid;
}
.links a:active,
.mobile-nav a:active {
  color: #91cee0;
  border-bottom: 2px solid;
}
.right {
  justify-content: flex-end !important;
}
.nav-wrapper .right a,
.mobile-nav .right a,
.landing-box a,
.cta a,
.section button {
  background: #47aecc;
  border: 2px solid #47aecc;
  margin-right: 20px;
  padding: 10px 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #2a2a2a;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
}
.right a.log-in {
  background: #2a2a2a;
  color: #47aecc;
}
.nav-wrapper .right a:hover {
  background: #91cee0;
  border-color: #91cee0;
}
.nav-wrapper .right a:hover,
.mobile-nav .right a:hover,
.landing-box a:hover,
.cta a:hover,
.section button:hover {
  background: #91cee0;
  border-color: #91cee0;
}

.right a.log-in:hover {
  background: #2a2a2a;
  border: 2px solid #91cee0;
  color: #91cee0;
}
.mobile-nav {
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.5s ease;
  filter: drop-shadow(2px 4px 6px #0005);
}
.mobile-nav .right {
  width: 100%;
  padding-left: 20px;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 2px solid #47aecc;
  margin-bottom: 15px;
}
.mobile-nav ul {
  flex-direction: column;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 3vw),
    50% 100%,
    0 calc(100% - 3vw)
  );
  background: #2a2a2a;
  padding-bottom: 40px;
  padding-top: 15px;
  margin-top: 0;
}
.mobile-nav li {
  text-align: center;
  line-height: 50px;
  font-size: 22px;
}
.mobile-nav li a {
  font-size: 22px;
}
.mobile-nav .close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  background: #47aecc;
  border: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-nav .close:hover {
  background: #91cee0;
}
