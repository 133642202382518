.faq {
  border: 2px solid rgb(29 29 29);
  position: relative;
  margin: 10px;
  cursor: pointer;
  box-shadow: 1px 2px 4px rgb(0 0 0 / 10%);
}
.faq .question {
  background: rgb(29 29 29);
  padding: 10px;
  z-index: 100;
}
.answer-wrapper {
  transition: 0.5s ease;
  overflow: hidden;
  z-index: 1;
}
.answer-wrapper .answer {
  padding: 10px;
}
