.quote {
  position: relative;
  padding: 0 60px;
  font-style: italic;
  font-weight: bold;
  font-size: 24px;
  margin: 50px 50px 50px 20px;
  color: #b5b5b5;
}
.quote svg {
  position: absolute;
  left: 0;
  top: -25px;
  height: 50px;
  width: 50px;
  color: #286e8f;
  transform: rotate(180deg);
}

.quote svg.right {
  position: absolute;
  left: unset;
  top: unset;
  bottom: -25px;
  right: 0;
  transform: rotate(0);
}
