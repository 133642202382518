.landing-box {
  border: 2px solid #286e8f;
  position: relative;
  margin: 120px 10px 50px 10px;
  border-radius: 50px 0 50px 0;
  min-height: 530px;
}
.landing-box > div {
  padding: 20px;
}
.landing-box.even {
  border-radius: 0 50px 0 50px;
}

.landing-box .img {
  position: absolute;
  padding: 0;
  width: 70%;
  right: calc(50% + 30px);
  top: 25px;
}
.landing-box .img img {
  width: 100%;
}

.landing-box .text {
  padding-left: 50%;
}

.landing-box h2 {
  display: inline-block;
  margin: 0;
  padding: 0 30px;
  position: absolute;
  top: -30px;
  left: calc(50% - 30px);
  color: #286e8f;
  background: #2a2a2a;
}
.landing-box h3 {
  display: flex;
  align-items: flex-end;
  font-weight: normal;
  justify-content: center;
  font-size: 3em;
  margin: 0.5em 0 0.7em;
}

.landing .message-container {
  padding-top: 30px;
}

.landing-box a:hover {
  background: #91cee0;
}

.landing-box .a-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, 100%);
  border: 20px solid #2a2a2a;
  background: #2a2a2a;
  width: calc(100% - 20px);
}
.landing-box.even {
  border-radius: 0 50px 0 50px;
  margin-top: 200px;
}
.landing-box.even h2 {
  left: 30px;
}
.landing-box.even .text {
  padding: 20px 50% 20px 20px;
}
.landing-box.even .img {
  right: unset;
  left: calc(50% + 30px);
}
