.contact form {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: #2c2c2c;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 50%);
  min-width: 364px;
}

.contact input,
.contact textarea {
  margin-bottom: 15px;
  margin-top: 5px;
  height: 28px;
  background: #121212;
  border: 2px solid transparent;
  padding: 5px 15px;
  font-size: 16px;
  font-family: inherit;
  color: #286e8f;
}

.contact form > div {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.contact textarea {
  height: 6em;
  line-height: 28px;
}

.contact button[type="submit"] {
  background: #286e8f;
  font-family: inherit;
  border: 2px solid transparent;
  margin-top: 30px;
  color: white;
  font-size: 18px;
  padding: 15px;
}

.contact .red {
  font-size: 0.8em;
  text-align: center;
  background: rgb(255 0 0 / 30%);
  padding: 20px;
}
.contact .green {
  font-size: 0.8em;
  text-align: center;
  background: rgb(0 255 0 / 20%);
  padding: 20px;
}
.time-option {
  padding: 5px !important;
  border-radius: 5px !important;
  background: #121212 !important;
  color: #47aecc !important;
  width: 80px;
  margin: 20px auto !important;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;
}
.time-option.selected,
.time-option:hover {
  background: #47aecc !important;
  color: #121212 !important;
}
.time-picker {
  position: absolute;
  top: 100%;
  width: 300px;
  display: flex;
  background: #2c2c2c;
  flex-wrap: wrap;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease;
}

.time-picker.show {
  pointer-events: all;
  opacity: 1;
}
