.feature-wrapper {
  display: flex;
  margin: 100px 0;
  min-height: 600px;
}
.feature-wrapper .title {
  color: #a9c5d2;
}
.graphic {
  flex: 40 1;
}
.feature-wrapper .content {
  padding-top: 10px;
  flex: 60 1;
  overflow-x: hidden;
  display: flex;
  transition: flex 0.5s ease;
  height: fit-content;
}
.feature-wrapper .title {
  font-size: 2em;
  line-height: normal;
}
.grey {
  color: #868686;
  text-align: center;
  display: block;
}
.options {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
}

.options button {
  width: 300px;
}
.section button {
  border: 0;
  color: rgb(40 110 143);
  background: #2c2c2c;
  min-width: 100px;
  padding: 10px;
  margin: 20px 0;
  font-family: inherit;
  border-radius: 50px;
  transition: 0.3s ease;
  font-size: 18px;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
}
button:hover {
  background-color: rgb(53 53 53);
}
.graphic img {
  width: 90%;
  position: relative;
}
.graphic .section {
  padding: 5px;
}
.grayscale {
  filter: grayscale(1);
  transition: 0.5s ease;
}
.grayscale:hover {
  filter: none;
}
.section {
  width: calc(100% - 10px);
  transition: 0.5s ease;
  padding: 0 10px;
  flex-shrink: 0;
  text-align: center;
}
.section p,
.section div {
  text-align: left;
}
.scenarios button {
  width: 42px;
  min-width: unset;
  margin-right: 10px;
  transition: 0.3s ease;
}
.scenarios button.selected {
  background: #47aecc;
  color: #121212;
  font-weight: bold;
}
.widget {
  position: absolute;
  top: 10.6%;
  right: 8.2%;
  left: 7.1%;
  bottom: 12.3%;
  background: #2a2a2a;
}

.tables-holder {
  display: flex;
  bottom: 0;
  position: absolute;
  width: 100%;
  background: #121212;
  height: 20%;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
}
.tables-holder .header {
  background: #252525;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
}
.tables-holder .header p {
  transform: rotate(-90deg);
  text-align: center;
}

.table {
  width: 20%;
  transition: 0.5s ease;
}
.table p {
  height: 100%;
  padding: 0;
  text-align: center;
  margin: 0;
}
.graphic .booking {
  transition: 0.5s ease;
  position: absolute;
  width: 15%;
  text-align: center;
  border-radius: 50px;
  transform: translateX(-50%);
  color: #aeaeae;
  font-weight: bold;
}
.booking.manual {
  background: #47aecc;
  color: #121212;
}
.widget .controls,
.widget .explanation {
  background: #252525;
  position: absolute;
  left: 10%;
  right: 10%;
  top: 10%;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s ease;
}

.widget .controls,
.widget .explanation {
  left: 5%;
  right: 5%;
  top: 4%;
}

.widget .controls .add {
  background: #2c2c2c;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  transition: 0.3s ease;
  cursor: pointer;
}

.widget .controls .add:hover {
  background: #47aecc;
  color: #121212;
}
.widget .controls .add svg.MuiSvgIcon-root {
  height: 70%;
  width: 70%;
}
.widget .explanation {
  display: flex;
  flex-direction: column;
}
.reset-next button {
  margin: 0 10px;
}
.widget.phone p {
  margin: 0;
}
.widget ul {
  margin-left: 0;
  padding-left: 10px;
  list-style-type: decimal;
}
.widget li {
  margin-top: 10px;
}
.reset-next {
  margin-bottom: 10px;
}
.booking.manual.green {
  color: green;
  background: green;
}
.booking.manual.blue {
  color: #286e8f;
  background: #286e8f;
}
.widget button.next {
  background: #286e8f;
  color: #121212;
}
.widget button.next:hover {
  background: #47aecc;
}
.explain {
  display: none;
}
