.message-container {
  max-width: 800px;
  display: flex;
  position: relative;
}

.host .message {
  padding: 100px 0px;
}
.picture {
  text-align: center;
}
.picture img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 1px 1px 8px #1e1e24;
}
.selection.show {
  background: #252525;
  padding: 3em;
  min-width: 400px;
  top: 100px;
}
.selection {
  text-align: center;
  top: -30px;
  margin-top: 0;
  transition: all 1s ease, background-color 0.5s ease;
  position: absolute;
}
.selection p {
  transition: 0.5s ease;
  opacity: 0;
}
.selection.show p {
  opacity: 1;
}

.button-holder button {
  padding: 10px;
  font-family: inherit;
  font-size: 1em;
  background: #2c2c2c;
  color: #286e8f;
  border: 2px solid transparent;
  margin: 20px;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  transition: 0.5s ease;
}

.button-holder button.selected,
.button-holder button:hover {
  color: rgb(255 255 255 / 80%);
  background: #47aecc;
}
.content {
  transition: 1s ease;
}
.content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}
/* .content-wrapper > div.content {
  width: calc(calc(100% - 100vw) / 2);
} */
