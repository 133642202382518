.nav-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
button.nav-icon {
  border: none;
  background: #2c2c2c;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  color: #47aecc;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 2px;
  transition: box-shadow 0.5s ease;
}
button.nav-icon > * {
  height: 26px;
  width: 26px;
}

.icon-nav-img-wrap {
  filter: invert(70%) sepia(22%) saturate(1117%) hue-rotate(150deg)
    brightness(85%) contrast(86%);
}
.nav-icon-wrap {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  min-width: 58px;
  padding-bottom: 20px;
}
button.nav-icon img {
  max-width: 26px;
  max-height: 26px;
}
button.nav-icon:hover,
button.nav-icon.active {
  color: #91cee0;
  box-shadow: 0 0 0 7px;
}

button.nav-icon:hover .icon-nav-img-wrap,
button.nav-icon.active .icon-nav-img-wrap {
  filter: invert(73%) sepia(9%) saturate(1115%) hue-rotate(147deg)
    brightness(105%) contrast(93%);
}

.features-window {
  display: flex;
  margin: 30px;
  padding: 30px;
  background: #2c2c2c;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 50%);
  min-height: 400px;
}
.features-graphic {
  position: relative;
  width: 210px;
  z-index: 2;
}

.features-graphic img {
  max-height: 700px;
}
.features-graphic .img-wrap {
  max-height: 700px;
  position: absolute;
  top: -50px;
  right: 0;
  transition: 0.5s ease;
  filter: drop-shadow(2px 4px 6px #0008);
}
.features-graphic.selected .img-wrap {
  right: calc(100%);
  transform: translateX(100%);
}
.half {
  display: flex;
  flex: 1;
}

.features-content {
  padding-left: 30px;
  flex: 1;
}

.features-content h2 {
  padding-right: 70px;
}
.features-window .expand {
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  display: flex;
  background: #47aecc;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  border-radius: 50%;
  top: 300px;
  right: 0;
  transform: translate(50%, -50%);
  transition: 0.5s ease;
  color: #2a2a2a;
  cursor: pointer;
}

.features-window .selected .expand {
  right: calc(100% - 360px);
}
.features-window .expand:hover {
  background: #91cee0;
}
.features-window .expand svg {
  transition: 0.5s ease;
}
.features-window .selected .expand svg {
  transform: rotate(-180deg);
}
.features-graphic .expand {
  display: none;
}
h2 .icon-nav-img-wrap {
  display: inline;
  filter: invert(97%) sepia(5%) saturate(1552%) hue-rotate(289deg)
    brightness(119%) contrast(76%);
}
h2 .icon-nav-img-wrap img {
  max-width: 0.8em;
  max-height: 0.8em;
}
