.trial .phone.feature-wrapper {
  max-width: 400px !important;
  margin: 50px auto 30px;
}

.trial .phone.feature-wrapper .content {
  display: none;
}

.trial .feature {
  padding: 40px 60px;
  margin: 60px;
  background: #252525;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
}

.trial .phone.feature-wrapper .explain {
  display: none;
}

@media (max-width: 750px) {
  .trial .phone.feature-wrapper .graphic .section {
    position: unset;
    top: unset;
    right: unset;
  }

  .trial .phone.feature-wrapper .graphic .section .show-hide {
    display: none;
  }

  .trial .feature-wrapper .graphic .section img {
    margin-left: 0;
    margin-top: 0;
  }

  .trial .feature-wrapper .graphic .section .widget.phone {
    margin-left: 0px;
    transform: translate(0);
  }
  .trial .feature-wrapper .graphic .section .widget {
    top: 10.6%;
  }
}
@media (max-width: 645px) {
  .trial .feature {
    padding: 0;
    margin: 0;
    background: transparent;
    box-shadow: none;
  }
}
